import React, { useState } from 'react'
import EditorComponent from './widgets/EditorComponent';


const EducationDetails = (props) => {
    const {index, educationData} = props;

    const [educationDetails, setEducationDetails] = useState(educationData);

    const handleInputChange = (e) => {
        const {target} = e;
        const {dataset, parentNode, value} = target;
        const {name} = dataset;

        const index = Number(
            dataset.index ? dataset.index : parentNode.dataset.index
        );
        educationDetails[index][name] = value;
        setEducationDetails(educationDetails);
       
        props.dispatch({
            type: 'field',
            field: 'educationData',
            value: educationDetails
        })
    }

    const handleEditorChange = (values) => {
        const {
            index,
            event
        } = values;

        const {target} = event;
        const {name, value} = target;

        educationDetails[index][name] = value;
        setEducationDetails(educationDetails);
       
        props.dispatch({
            type: 'field',
            field: 'educationData',
            value: educationDetails
        })
    }

    return(
        <div data-index={index}>
            <div className="mb-3">
                <label htmlFor="eduUniversity" className="form-label">College name</label>
                <input data-index={index} type="text" name={"eduUniversity"} value={educationData[index] && educationData[index]['eduUniversity']} data-name="eduUniversity" onChange={handleInputChange} className="form-control" id="eduUniversity" aria-describedby="eduUniversity" />
            </div>
            <div className="mb-3">
                <label htmlFor="eduTitle" className="form-label">Degree Title</label>
                <input data-index={index} type="text"  data-name="eduTitle" value={educationData[index] && educationData[index]['eduTitle']} onChange={handleInputChange} className="form-control" id="eduTitle" aria-describedby="eduTitle" />
            </div>
            <div className="mb-3">
                <label htmlFor="eduLocation" className="form-label">Location</label>
                <input data-index={index} type="text"  data-name="eduLocation" value={educationData[index] && educationData[index]['eduLocation']} onChange={handleInputChange} className="form-control" id="eduLocation" aria-describedby="eduLocation" />
            </div>
            <div className="mb-3">
                <label htmlFor="eduFromDate" className="form-label">From Date</label>
                <input data-index={index} type="date" data-name="eduFromDate" value={educationData[index] && educationData[index]['eduFromDate']} onChange={handleInputChange} className="form-control" id="eduFromDate" aria-describedby="eduFromDate" />
            </div>
            <div className="mb-3">
                <label htmlFor="eduToDate" className="form-label">To Date</label>
                <input data-index={index} type="date"  data-name="eduToDate" value={educationData[index] && educationData[index]['eduToDate']} onChange={handleInputChange} className="form-control" id="eduToDate" aria-describedby="eduToDate" />
            </div>
            <div className="mb-5">
                <label htmlFor="eduSummary" className="form-label">Summary</label>
                <EditorComponent 
                    index={index}
                    name="eduSummary"
                    rows={5} 
                    content={educationData[index] && educationData[index]['eduSummary']} 
                    className="form-control" 
                    id="eduSummary" 
                    aria-describedby="eduSummary"
                    handleChange={handleEditorChange} />
            </div>
        </div>
    )
}

export default function EducationComponent({dispatch, initialValues}) {
    const {educationData} = initialValues;
    const defaultValues = {
        eduUniversity: '',
        eduTitle: '',
        eduLocation: '',
        eduFromDate: '',
        eduToDate: '',
        eduSummary: ''
    };
    
    const [items, setItems] = useState(educationData);

    const onAddItem = (e) => {
        const updatedValue = (educationData || []).concat(defaultValues);
        setItems(updatedValue);
        dispatch({
            type: 'field',
            field: 'educationData',
            value: updatedValue
        })
    }

    const onRemoveItem = (e) => {
        const {target} = e;
        const {dataset, parentNode} = target;
        const index = Number(
            dataset.index ? dataset.index : parentNode.dataset.index
        );
        const updatedSelection = (items || []).filter(
            (row, idx) => idx !== index
        );
        setItems(updatedSelection);
        dispatch({
            type: 'field',
            field: 'educationData',
            value: updatedSelection
        })
    }

    return (
      <div>
        <a className='btn btn-primary mb-3' onClick={onAddItem}>Add Education</a>
        {
            (items || []).map((item, idx) => {
                const index = 'edu-collapse-'+idx;
                const heading = 'edu-heading-'+idx;
                const eduUniversity = educationData[idx] && educationData[idx].eduTitle;
                return(
                    <div key={idx}>
                        <div className="accordion-item" data-index={idx}>
                        <h2 className="accordion-header" id={heading} data-index={idx}>
                        <button className="accordion-button" data-index={idx} type="button" data-bs-toggle="collapse" data-bs-target={`#${index}`} aria-expanded="true" aria-controls={index}>
                            <a onClick={onRemoveItem} className='btn' data-index={idx}>X</a> {eduUniversity}
                        </button>
                        </h2>
                        <div id={index} className="accordion-collapse collapse show" aria-labelledby={heading}>
                        <div className="accordion-body">
                                <EducationDetails index={idx} dispatch={dispatch} educationData={educationData} />
                        </div>
                        </div>
                    </div>
                    </div>
                )
            })
        }
        
      </div>
    );
  }