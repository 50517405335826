

import React, {useState, useReducer} from 'react';
import './App.scss';

import data from './data';

import EducationComponent from './EducationComponent';
import SkillsComponent from './SkillsComponent';
import SocialComponent from './SocialComponent';
import WorkComponent from './WorkComponent';
import PersonalDetailsComponent from './PersonalDetailsComponent';
import ReferencesComponent from './ReferencesComponent';
import 'bootstrap/dist/js/bootstrap.bundle';
import Template1 from './templates/Template1'
import Template2 from './templates/Template2'
import Template3 from './templates/Template3'
import Template4 from './templates/Template4'
import Template5 from './templates/Template5'
import Template6 from './templates/Template6'
import Template7 from './templates/Template7'
import Template8 from './templates/Template8'
import Template9 from './templates/Template9'
import Template10 from './templates/Template10'
import Template11 from './templates/Template11'
import Template12 from './templates/Template12'
import Template13 from './templates/Template13'

import Header from './Header';
import Footer from './Footer';

function cvReducer(state, action) {
    // console.log(`Action triggered`, action)
    switch(action.type) {
        
        case 'field': {
            localStorage.setItem('mycv', JSON.stringify({
                ...state,
                [action.field]: action.value
            }));
            return {
                ...state,
                [action.field]: action.value
            }
        }
        default: 
            break;
    }

    return state;
}

const initialState = JSON.parse(localStorage.getItem('mycv')) ? JSON.parse(localStorage.getItem('mycv')) : data;

export const TemplateComponent = (state) => {
    const {template, selectedFont} = state;
    switch (template) {
        case 'template1':
            return <div className={`${template} ${selectedFont} shadow-lg bg-white rounded`}><Template1 {...state} /></div>;

        case 'template2':
            return <div className={`${template} ${selectedFont} shadow-lg bg-white rounded`}><Template2 {...state} /></div>;
        case 'template3':
            return <div className={`${template} ${selectedFont} shadow-lg bg-white rounded`}><Template3 {...state} /></div>;
        case 'template4':
            return <div className={`${template} ${selectedFont} shadow-lg bg-white rounded`}><Template4 {...state} /></div>;
        case 'template5':
            return <div className={`${template} ${selectedFont} shadow-lg bg-white rounded`}><Template5 {...state} /></div>;
        case 'template6':
            return <div className={`${template} ${selectedFont} shadow-lg bg-white rounded`}><Template6 {...state} /></div>;
        case 'template7':
            return <div className={`${template} ${selectedFont} shadow-lg bg-white rounded`}><Template7 {...state} /></div>;
        case 'template8':
            return <div className={`${template} ${selectedFont} shadow-lg bg-white rounded`}><Template8 {...state} /></div>;
        case 'template9':
            return <div className={`${template} ${selectedFont} shadow-lg bg-white rounded`}><Template9 {...state} /></div>;
        case 'template10':
            return <div className={`${template} ${selectedFont} shadow-lg bg-white rounded`}><Template10 {...state} /></div>;
        case 'template11':
            return <div className={`${template} ${selectedFont} shadow-lg bg-white rounded`}><Template11 {...state} /></div>;
        case 'template12':
            return <div className={`${template} ${selectedFont} shadow-lg bg rounded`}><Template12 {...state} /></div>;
        case 'template13':
            return <div className={`${template} ${selectedFont} shadow-lg bg rounded`}><Template13 {...state} /></div>;
        default:
            return <div className={`${template} ${selectedFont} shadow-lg bg-white rounded`}><Template1 {...state} /></div>;
    }
};


function App() { 
    const [state, dispatch] = useReducer(cvReducer, initialState);
    const {
        showSocial
    } = state;

    const handleCheckBoxChange = (event) => {
        const {target} = event;
        const {name, checked} = target;
        dispatch({
            type: 'field',
            field: name,
            value: checked
        })
    }

    // const clearDefaultData = () => {

    // }

    return (
        <div>
            
            <Header dispatch={dispatch} initialValues={state}/>
            
            <div className={'container'}>
                
                
                <div className="item-1">
                
                <form className='mb-8'>
                    {/* <button type="button" className="btn clear-data">
                        <Icon icon={faTrash} /> {' '}
                        Clear default data
                    </button> */}
                    <div className="accordion" id="resumeAccordion">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <label>Personal Details</label>
                            </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#resumeAccordion">
                            <div className="accordion-body">
                                <PersonalDetailsComponent dispatch={dispatch} initialValues={state} />
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Education Details
                            </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#resumeAccordion">
                            <div className="accordion-body">
                                <EducationComponent dispatch={dispatch} initialValues={state} />
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Skills
                            </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#resumeAccordion">
                            <div className="accordion-body">
                                <SkillsComponent dispatch={dispatch} initialValues={state} />
                            </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                            
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Social
                            </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#resumeAccordion">
                            <div className="accordion-body">
                                <div className="form-check">
                                    <input type="checkbox" name="showSocial" checked={showSocial} className="form-check-input" id="showSocial" onChange={handleCheckBoxChange} />
                                    <label className="form-check-label" htmlFor="showSocial">{' '} Show in CV</label>
                                </div>
                                <SocialComponent dispatch={dispatch} initialValues={state} />
                            </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Work Details
                            </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#resumeAccordion">
                            <div className="accordion-body">
                                <WorkComponent dispatch={dispatch} initialValues={state} />
                            </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                References
                            </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#resumeAccordion">
                            <div className="accordion-body">
                                <ReferencesComponent dispatch={dispatch} initialValues={state} />
                            </div>
                            </div>
                        </div>
                    </div>   
                </form>
                </div>
                <TemplateComponent {...state} />
                
            
            </div>
            <Footer />
        </div>
    );
}
export default App;