import React from 'react';
import HTMLParserWrapperComponent from '../widgets/HTMLParserWrapperComponent';

function EducationDataComponent(props) {
    const {educationData} = props;
    return (
        
        <div className="accordion" id="educationAccordion">                     
                {
                    (educationData || []).map((item, idx) => {
                        return (
                            <p className="title" key={idx}>
                                <small><strong>{item.eduUniversity} | {item.eduLocation} | {item.eduTitle} | {item.eduFromDate} - {item.eduToDate}</strong></small> <br />
                                <small><HTMLParserWrapperComponent content={item.eduSummary} /></small>
                            </p>
                        )
                    })
                }
            </div>
    );
}

export default EducationDataComponent;