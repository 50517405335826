import React from 'react';

function ReferencesDataComponent(props) {
    const {referenceData} = props;
    return (
        
        <div className="accordion" id="referencesAccordion">                     
            {
                (referenceData || []).map((item, idx) => {
                    return (
                        <p className="title" key={idx}>
                            <small><strong>{item.name}</strong></small> <br />
                            <small>{item.title}</small> <br />
                            <small>{item.email}</small> <br />
                            <small>{item.phone}</small> <br />
                        </p>
                    )
                })
            }
        </div>
    );
}

export default ReferencesDataComponent;