

import React from 'react';
import '../App.scss';
import 'bootstrap/dist/js/bootstrap.bundle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faGlobe, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import SocialIconsComponent from '../widgets/SocialIconsComponent';
import LanguagesDataComponent from '../widgets/LanguagesDataComponent';
import SoftSkillsComponent from '../widgets/SoftSkillsComponent';
import ProfessionalSkillsComponent from '../widgets/ProfessionalSkillsComponent';
import ContactComponent from '../widgets/ContactComponent';
import EducationDataComponent from '../widgets/EducationDataComponent';
import ReferencesDataComponent from '../widgets/ReferencesDataComponent';
import ExperienceDataComponent from '../widgets/ExperienceDataComponent';
import HTMLParserWrapperComponent from '../widgets/HTMLParserWrapperComponent';

function Template2(props) {
    const { name, about, email, address, phone, title, technicalSkills, languages, softSkills,
        linkedinURL, twitterURL, websiteURL,
        educationData,
        jobData,
        showSocial,
        referenceData
    } = props;

    return (
        <>

            <div className="item-3 p-3 mb-5 bg-white">
                <div>
                    <div className='mb-4'>
                        <h4 className="display-6">{name}</h4>
                    </div>
                    <div className='mb-4'>
                        <h6>{title}</h6>
                    </div>
                    <hr />
                    <div className='contact-about'>
                        <div className='mb-4 mr-4'>
                            <h6 className="display-7">{`ABOUT ME`}</h6>
                            <small>
                                <HTMLParserWrapperComponent content={about} />
                            </small>
                        </div>
                        <ContactComponent 
                            email={email} 
                            phone={phone} 
                            address={address}
                            color="orange"  
                        />
                        <SocialIconsComponent 
                            linkedinURL={linkedinURL} 
                            websiteURL={websiteURL} 
                            twitterURL={twitterURL}
                            showSocial={showSocial}
                            color="gray"  
                        />
                    </div>
                    <hr />

                    <ProfessionalSkillsComponent technicalSkills={technicalSkills} />
                    <SoftSkillsComponent softSkills={softSkills} />
                    <LanguagesDataComponent languages={languages} />

                </div>

                <hr />

                <div>
                    {/* <div className='mb-4'>
                        <h5 className="display-7">{`ABOUT ME`}</h5>
                        <small>
                            {about}
                        </small>
                    </div> */}
                    <div className="mb-3">
                        <h6 className="display-7"> EXPERIENCE </h6>
                        <ExperienceDataComponent jobData={jobData} />
                    </div>
                    
                    <div className="mb-3">
                        <h6 className="display-7 royal-blue-underline">
                        {" "}
                        EDUCATION{" "}
                        </h6>
                        <EducationDataComponent educationData={educationData} />
                    </div>

                    <div className="mb-3">
                        <h6 className="display-7"> REFERENCES </h6>
                        <ReferencesDataComponent referenceData={referenceData} />
                    </div>
                </div>


            </div>
        </>
    );
}
export default Template2;