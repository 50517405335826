import React from "react";
import '../App.scss';
import "bootstrap/dist/js/bootstrap.bundle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import {
  faPhone,
  faGlobe,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import profilePic from '../images/150.png';

import SocialIconsComponent from '../widgets/SocialIconsComponent';
import LanguagesDataComponent from '../widgets/LanguagesDataComponent';
import SoftSkillsComponent from '../widgets/SoftSkillsComponent';
import ProfessionalSkillsComponent from '../widgets/ProfessionalSkillsComponent';
import ContactComponent from '../widgets/ContactComponent';
import EducationDataComponent from '../widgets/EducationDataComponent';
import ReferencesDataComponent from '../widgets/ReferencesDataComponent';
import ExperienceDataComponent from '../widgets/ExperienceDataComponent';
import HTMLParserWrapperComponent from '../widgets/HTMLParserWrapperComponent';

function Template4(props) {
  const {
    name,
    about,
    email,
    address,
    phone,
    title,
    technicalSkills,
    languages,
    softSkills,
    linkedinURL,
    twitterURL,
    websiteURL,
    educationData,
    jobData,
    showSocial,
    referenceData,
  } = props;

  return (
    <div className="item-3 mb-5 bg-white">
      <div className="tpc4-header">
        <div className="tpc-bar1"></div>
        <div className="tpc-bar2"></div>
        <div className="tpc-bar3"></div>
      </div>
      <div className="tpc4-title p-5">
        
        <div className="tpc-4">
            <div className="mb-4">
                
                <h2 className="display-2">{name}</h2>
                <h4 className="ml-4">{title}</h4> <br />
                <div className="mb-4 mr-4">
                    <h6 className="display-7 text-info">{`ABOUT ME`}</h6>
                    <small><HTMLParserWrapperComponent content={about} /></small>
                </div>
                
                <ProfessionalSkillsComponent technicalSkills={technicalSkills} />
                <LanguagesDataComponent languages={languages} />
                <div className="mb-3">
                    <h6 className="display-7 text-info">
                    {" "}
                    EXPERIENCE{" "}
                    </h6>
                    <ExperienceDataComponent jobData={jobData} />
                </div>
                <div className="mb-3">
                    <h6 className="display-7 text-info">
                    {" "}
                    REFERENCES{" "}
                    </h6>
                    <ReferencesDataComponent referenceData={referenceData} />
                </div>
                
            </div>
            <div>
                <div className="profile-pic">
                    {/* <img src={profilePic} className="img-thumbnail" alt="profile pic" /> */}
                </div>
                <ContactComponent 
                        email={email} 
                        phone={phone} 
                        address={address}
                        color="#6c757d"  
                    />
                <SoftSkillsComponent softSkills={softSkills} />
                <div className="mb-3">
                    <h6 className="display-7 text-info">
                    {" "}
                    EDUCATION{" "}
                    </h6>
                    <EducationDataComponent educationData={educationData} />
                </div>
            </div>
        </div>

        <SocialIconsComponent 
            linkedinURL={linkedinURL} 
            websiteURL={websiteURL} 
            twitterURL={twitterURL}
            showSocial={showSocial}
            color="gray"  
        />
        
      </div>
    
      <div className="tpc4-footer">
        <div className="tpc-bar3"></div>
        <div className="tpc-bar2"></div>
        <div className="tpc-bar1"></div>
      </div>
    </div>
  );
}
export default Template4;
