import React from 'react';
import { DefaultEditor } from 'react-simple-wysiwyg';

function EditorComponent(props) {

    const {content, name, ...rest} = props;

    const handleChange = (event) => {
        props.handleChange({event, ...rest});
    }
    return (
        
        <DefaultEditor name={name} value={content} onChange={handleChange} {...rest} />
    );
}

export default EditorComponent;