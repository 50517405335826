import React from 'react';

function LanguagesComponent(props) {
    const {languages, badgeColor = 'bg-success'} = props;
    return (
        <div className="mb-3">
            <h6> LANGUAGES KNOWN </h6>
            <label htmlFor="emailLabel" className="form-label">
                {
                    (languages || []).map((item, idx) => {
                        return (
                            <span key={idx} className={`badge rounded-pill ${badgeColor}`}>{item.value}</span>
                        )
                    })
                }
                
            </label>
        </div>
    );
}

export default LanguagesComponent;