import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe, faEnvelope, faPhone
} from "@fortawesome/free-solid-svg-icons";

function ContactComponent(props) {
    const {color, email, phone, address} = props;
    return (
        
        <div className='mb-4'>
            <h6 className="display-9"> CONTACT </h6>
            <p className="title">
                <small><FontAwesomeIcon icon={faEnvelope} color={color} />{' '}{email}</small> <br />
                <small><FontAwesomeIcon icon={faPhone} color={color} /> {' '}{phone}</small> <br />
                <small><FontAwesomeIcon icon={faGlobe} color={color} />{' '}{address}</small>
            </p>
        </div>
    );
}

export default ContactComponent;