import React from 'react';

function SoftSkillsComponent(props) {
    const {softSkills, badgeColor = 'bg-primary', margin = 'mb-3'} = props;
    return (
        
        <div className={margin}>
            <h6> SOFT SKILLS </h6>
                <label htmlFor="emailLabel" className="form-label">
                <ul className="list-unstyled">
                {
                    (softSkills || []).map((item, idx) => {
                        return (
                            <li key={idx} className={`badge rounded-pill ${badgeColor}`}>{item.value}</li>
                        )
                    })
                }
                </ul>
            </label>
        </div>
    );
}

export default SoftSkillsComponent;