const TEMPLATES = [
    'template1',
    'template2',
    'template3',
    'template4',
    'template5',
    'template6',
    'template7',
    'template8',
    'template9',
    'template10',
    'template11',
    'template12',
    'template13'
];

const FONTS = [
    'helvetica',
    'monospace',
    'sansserif',
    'gill-sans',
    'courier',
    'roboto',
    'barlow',
    'gothic',
    'funky'
]

export {
    TEMPLATES,
    FONTS
};
