import React, { useState } from 'react'
import { faSuitcase } from '@fortawesome/free-solid-svg-icons'
import Icon from './Icon';
import EditorComponent from './widgets/EditorComponent';

const JobDetails = (props) => {
    const {index, jobData} = props;

    const [jobDetails, setJobDetails] = useState(jobData);

    const handleInputChange = (e) => {
        const {target} = e;
        const {dataset, parentNode, value} = target;
        const {name} = dataset;

        const index = Number(
            dataset.index ? dataset.index : parentNode.dataset.index
        );
        jobDetails[index][name] = value;
        setJobDetails(jobDetails);
       
        props.dispatch({
            type: 'field',
            field: 'jobData',
            value: jobDetails
        })
    }

    const handleEditorChange = (values) => {
        const {
            index,
            event
        } = values;

        const {target} = event;
        const {name, value} = target;

        jobDetails[index][name] = value;
        setJobDetails(jobDetails);
       
        props.dispatch({
            type: 'field',
            field: 'jobData',
            value: jobDetails
        })
    }

    return(
        <div data-index={index}>
            <div className="mb-3">
                <label htmlFor="jobCompanyName" className="form-label">Company name</label>
                <input data-index={index} type="text" value={jobData[index] && jobData[index]['jobCompanyName']} data-name="jobCompanyName" onChange={handleInputChange} className="form-control" id="jobCompanyName" aria-describedby="work companyname" />
            </div>
            <div className="mb-3">
                <label htmlFor="jobTitle" className="form-label">Job Title</label>
                <input data-index={index} type="text" data-name="jobTitle" value={jobData[index] && jobData[index]['jobTitle']} onChange={handleInputChange} className="form-control" id="jobTitle" aria-describedby="jobTitle" />
            </div>
            <div className="mb-3">
                <label htmlFor="jobLocation" className="form-label">Location</label>
                <input data-index={index} type="text" data-name="jobLocation" value={jobData[index] && jobData[index]['jobLocation']} onChange={handleInputChange} className="form-control" id="jobLocation" aria-describedby="jobLocation" />
            </div>
            <div className="mb-3">
                <label htmlFor="jobFromDate" className="form-label">From Date</label>
                <input data-index={index} type="date" data-name="jobFromDate" value={jobData[index] && jobData[index]['jobFromDate']} onChange={handleInputChange} className="form-control" id="jobFromDate" aria-describedby="jobFromDate" />
            </div>
            <div className="mb-3">
                <label htmlFor="jobToDate" className="form-label">To Date</label>
                <input data-index={index} type="date"  data-name="jobToDate" value={jobData[index] && jobData[index]['jobToDate']} onChange={handleInputChange} className="form-control" id="jobToDate" aria-describedby="jobToDate" />
            </div>
            <div className="mb-5">
                <label htmlFor="jobSummary" className="form-label">Summary</label>
                <EditorComponent 
                    index={index}
                    name="jobSummary"
                    rows={5} 
                    content={jobData[index] && jobData[index]['jobSummary']} 
                    className="form-control" 
                    id="jobSummary" 
                    aria-describedby="jobSummary"
                    handleChange={handleEditorChange} />
            </div>
        </div>
    )
}

export default function WorkComponent({dispatch, initialValues}) {
    const {jobData} = initialValues;
    const defaultValues = {
        jobCompanyName: '',
        jobTitle: '',
        jobLocation: '',
        jobFromDate: '',
        jobToDate: '',
        jobSummary: ''
    };
    
    const [items, setItems] = useState(jobData);

    const onAddItem = (e) => {
        const updatedValue = (jobData || []).concat(defaultValues);
        setItems(updatedValue);
        dispatch({
            type: 'field',
            field: 'jobData',
            value: updatedValue
        })
    }

    const onRemoveItem = (e) => {
        const {target} = e;
        const {dataset, parentNode} = target;
        const index = Number(
            dataset.index ? dataset.index : parentNode.dataset.index
        );
        const updatedSelection = (items || []).filter(
            (row, idx) => idx !== index
        );
        setItems(updatedSelection);
        dispatch({
            type: 'field',
            field: 'jobData',
            value: updatedSelection
        })
    }

    return (
      <div>
        <a className='btn btn-primary mb-3' onClick={onAddItem}><Icon icon={faSuitcase} /> {' '}  Add Work</a>
        {
            (items || []).map((item, idx) => {
                const index = 'work-collapse-'+idx;
                const heading = 'work-heading-'+idx;
                const jobCompanyName = jobData[idx] && jobData[idx].jobCompanyName;
                return(
                    <div key={idx}>

                        <div className="accordion-item" data-index={idx}>
                        <h2 className="accordion-header" id={heading} data-index={idx}>
                        <button className="accordion-button" data-index={idx} type="button" data-bs-toggle="collapse" data-bs-target={`#${index}`} aria-expanded="true" aria-controls={index}>
                            <a onClick={onRemoveItem} className='btn' data-index={idx}>X</a> 
                            {jobCompanyName}
                        </button>
                        </h2>
                        <div id={index} className="accordion-collapse collapse show" aria-labelledby={heading}>
                        <div className="accordion-body">
                                <JobDetails index={idx} dispatch={dispatch} jobData={jobData} />
                        </div>
                        </div>
                    </div>
                    </div>
                )
            })
        }
        
      </div>
    );
  }