

import React from 'react';
import '../App.scss';
import 'bootstrap/dist/js/bootstrap.bundle';

import SocialIconsComponent from '../widgets/SocialIconsComponent';
import LanguagesDataComponent from '../widgets/LanguagesDataComponent';
import SoftSkillsComponent from '../widgets/SoftSkillsComponent';
import ProfessionalSkillsComponent from '../widgets/ProfessionalSkillsComponent';
import ContactComponent from '../widgets/ContactComponent';
import EducationDataComponent from '../widgets/EducationDataComponent';
import ReferencesDataComponent from '../widgets/ReferencesDataComponent';
import ExperienceDataComponent from '../widgets/ExperienceDataComponent';
import HTMLParserWrapperComponent from '../widgets/HTMLParserWrapperComponent';

function Template13(props) {
    const {name, about, email, address, phone, title, technicalSkills, languages, softSkills,
        linkedinURL, twitterURL, websiteURL,
        educationData,
        jobData,
        showSocial,
        referenceData
    } = props;

    const shadowCSS = `item-3 p-5 bg rounded`;

    return (
        <>
            <div>
                <div className="p-3 top-container">
                    <div className='mb-2'>
                        <h3 className="display-6">{name}</h3>
                    </div>
                    <div className='mb-4'>
                        <h5 className="role">{title}</h5>
                    </div>
                    <div className='contact-about'>
                        <div className='mb-4'>
                            <h6 className="display-9">{`ABOUT ME`}</h6>
                            <small>
                                <HTMLParserWrapperComponent content={about} />
                            </small>
                        </div>
                        
                        <ContactComponent 
                            email={email} 
                            phone={phone} 
                            address={address}
                            color="#fff"  
                        />
                        <SocialIconsComponent 
                            linkedinURL={linkedinURL} 
                            websiteURL={websiteURL} 
                            twitterURL={twitterURL}
                            showSocial={showSocial}
                            color="#fff"  
                        />
                    </div>
                </div>
            </div>
            <div className="center-container p-3">
                <div>
                        <div className="mb-3">
                            <h6 className="display-7"> EXPERIENCE </h6>                       
                            <ExperienceDataComponent jobData={jobData} />
                        </div>
                </div>
                <div className="divider"></div>
                <div>
                    <div className="mb-3">
                        <h6 className="display-7"> EDUCATION </h6>
                        <EducationDataComponent educationData={educationData} />
                    </div>
                </div>
            </div>
            <div className="p-3 bottom-container">
                <h6 className="display-7"> REFERENCES </h6>
                <ReferencesDataComponent referenceData={referenceData} />
            </div>
        </>
    );
}
export default Template13;