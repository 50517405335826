import React, { useState } from "react";
import '../App.scss';
import "bootstrap/dist/js/bootstrap.bundle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faGlobe,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import profilePic from '../images/150.png';
import SocialIconsComponent from '../widgets/SocialIconsComponent';
import LanguagesDataComponent from '../widgets/LanguagesDataComponent';
import SoftSkillsComponent from '../widgets/SoftSkillsComponent';
import ProfessionalSkillsComponent from '../widgets/ProfessionalSkillsComponent';
import EducationDataComponent from '../widgets/EducationDataComponent';
import ReferencesDataComponent from '../widgets/ReferencesDataComponent';
import ExperienceDataComponent from '../widgets/ExperienceDataComponent';
import HTMLParserWrapperComponent from '../widgets/HTMLParserWrapperComponent';
import ContactComponent from '../widgets/ContactComponent';

function Template6(props) {
  const {
    name,
    about,
    email,
    address,
    phone,
    title,
    technicalSkills,
    languages,
    softSkills,
    linkedinURL,
    twitterURL,
    websiteURL,
    educationData,
    jobData,
    showSocial,
    referenceData,
  } = props;

  const [background, setBackground] = useState(true);
  

  const toggleBackground = () => {
    setBackground(!background)
  }

  return (
    <>
    <div className="header-bg">
    <input 
        type="checkbox" 
        checked={background} 
        name="background" 
        onChange={toggleBackground}
        /> {' '}
      Header Background 
      
    </div>
      <div className={`tpc-title tpc-title2 ${background ? 'pattern3': 'empty-pattern'}`}>
      
        <div className="mb-4">
            
            <h2 className="display-2">{name}</h2>
            <h4 className="ml-4 center-title">{title}</h4>
        </div>
            <div className="title tpc2-contact">
                <div>

                </div>
                <div>
                    <small>
                    <FontAwesomeIcon icon={faEnvelope} color="#6c757d" /> {email}
                    </small>{" "}
                    <small>
                    <FontAwesomeIcon icon={faPhone} color="#6c757d" /> {phone}
                    </small>{" "}
                    <small>
                    <FontAwesomeIcon icon={faGlobe} color="#6c757d" /> {address}
                    </small>
                </div>
              </div>
            </div>
        {/* <div>
            <img src={profilePic} class="rounded-circle" alt="profile pic" />
        </div> */}
        <div className="pl-4 mb-4">
              
      </div>
      <div className="tpc-3 item-3 p-3 mb-5 bg-white">
        <div>
          <div className='contact-about'>
              <div className='mb-4'>
                  <h6 className="display-9">{`ABOUT ME`}</h6>
                  <small>
                      <HTMLParserWrapperComponent content={about} />
                  </small>
              </div>
              
              <ContactComponent 
                  email={email} 
                  phone={phone} 
                  address={address}
                  color="#212529"  
              />
              <SocialIconsComponent 
                  linkedinURL={linkedinURL} 
                  websiteURL={websiteURL} 
                  twitterURL={twitterURL}
                  showSocial={showSocial}
                  color="#212529"  
              />
          </div>

          <ProfessionalSkillsComponent technicalSkills={technicalSkills} />
          <SoftSkillsComponent softSkills={softSkills} />
          <LanguagesDataComponent languages={languages} />
        </div>

        <div>
          <div className="mb-3">
            <h6 className="display-7 royal-blue-underline">
              {" "}
              EXPERIENCE{" "}
            </h6>
            <ExperienceDataComponent jobData={jobData} />
          </div>
          <div className="mb-3">
            <h6 className="display-7 royal-blue-underline">
              {" "}
              EDUCATION{" "}
            </h6>
            <EducationDataComponent educationData={educationData} />
          </div>

          <div className="mb-3">
            <h6 className="display-7 royal-blue-underline">
              {" "}
              REFERENCES{" "}
            </h6>
            <ReferencesDataComponent referenceData={referenceData} />
          </div>
        </div>
      </div>
      <div className="tpc6-footer2">
        {/* <div className="triangle-bottomleft"></div>
        <div className="triangle-bottomright"></div> */}
      </div>
    </>
  );
}
export default Template6;
