

import React from 'react';
import '../App.scss';
import 'bootstrap/dist/js/bootstrap.bundle';

import SocialIconsComponent from '../widgets/SocialIconsComponent';
import LanguagesDataComponent from '../widgets/LanguagesDataComponent';
import SoftSkillsComponent from '../widgets/SoftSkillsComponent';
import ProfessionalSkillsComponent from '../widgets/ProfessionalSkillsComponent';
import ContactComponent from '../widgets/ContactComponent';
import EducationDataComponent from '../widgets/EducationDataComponent';
import ReferencesDataComponent from '../widgets/ReferencesDataComponent';
import ExperienceDataComponent from '../widgets/ExperienceDataComponent';
import HTMLParserWrapperComponent from '../widgets/HTMLParserWrapperComponent';

function Template12(props) {
    const {name, about, email, address, phone, title, technicalSkills, languages, softSkills,
        linkedinURL, twitterURL, websiteURL,
        educationData,
        jobData,
        showSocial,
        referenceData
    } = props;

    const shadowCSS = `item-3 p-5 bg rounded`;

    return (
        <>

            <div className={shadowCSS}>
                <div>
                    <div className='mb-2'>
                        <h4 className="display-6">{name}</h4>
                    </div>
                    <div className='mb-5'>
                        <h6 className="role">{title}</h6>
                    </div>
                    <div className='contact-about'>
                        <div className='mb-4'>
                            <h6 className="display-9">{`ABOUT ME`}</h6>
                            <small>
                                <HTMLParserWrapperComponent content={about} />
                            </small>
                        </div>
                        
                        <ContactComponent 
                            email={email} 
                            phone={phone} 
                            address={address}
                            color="#212529"  
                        />
                        <SocialIconsComponent 
                            linkedinURL={linkedinURL} 
                            websiteURL={websiteURL} 
                            twitterURL={twitterURL}
                            showSocial={showSocial}
                            color="#212529"  
                        />
                    </div>
                </div>

                
                <div>
                    <ProfessionalSkillsComponent technicalSkills={technicalSkills} badgeColor={''} />
                    <SoftSkillsComponent softSkills={softSkills} badgeColor={''} margin={'mb-1'}/>
                    <LanguagesDataComponent languages={languages} badgeColor={''} />
                    <div className="mb-3">
                        <h6 className="display-9"> EXPERIENCE </h6>                       
                        <ExperienceDataComponent jobData={jobData} />
                    </div>
                    <div className="mb-3">
                        <h6 className="display-9"> EDUCATION </h6>
                        <EducationDataComponent educationData={educationData} />
                    </div>

                    <div className="mb-3">
                        <h6 className="display-9"> REFERENCES </h6>
                        <ReferencesDataComponent referenceData={referenceData} />
                    </div>
                </div>
                
                
            </div>
        </>
    );
}
export default Template12;