
const data = {
    name: 'John Doe',
    email: 'johndoe@gmail.com',
    title: 'Software Engineer',
    address: 'San Jose',
    phone: 9876543210,
    profilePic: '',
    about: `
    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a 
    galley of type and scrambled it to make a type specimen book.`,
    technicalSkills: [{
        label: 'JavaScript',
        value: 'JavaScript'
    },{
        label: 'HTML',
        value: 'HTML'
    },{
        label: 'CSS',
        value: 'CSS'
    },{
        label: 'ReactJS',
        value: 'ReactJS'
    },{
        label: 'Database',
        value: 'Database'
    }],
    softSkills: [{
        label: 'Critical thinking',
        value: 'Critical thinking'
    },{
        label: 'Leadership',
        value: 'Leadership'
    },{
        label: 'Public speaker',
        value: 'Public speaker'
    },{
        label: 'Problem solving',
        value: 'Problem solving'
    },{
        label: 'Professional attitude',
        value: 'Professional attitude'
    }],
    languages: [{
        label: 'English',
        value: 'English'
    },{
        label: 'French',
        value: 'French'
    },{
        label: 'Tamil',
        value: 'Tamil'
    },],
    linkedinURL: 'linkedin.com/john-doe',
    twitterURL: 'twitter.com/johndoe',
    websiteURL: 'johndoe.com',
    keyValues: [],
    educationData: [{
        eduUniversity: 'Yale University',
        eduTitle: 'Bachelor of Engineering(Computers)',
        eduLocation: 'New York',
        eduFromDate:'2002-06-06',
        eduToDate: '2006-03-06',
        eduSummary: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a 
        galley of type and scrambled it to make a type specimen book.`
    },
],
    jobData: [{
        jobCompanyName: 'Amazon',
        jobLocation: 'New York',
        jobFromDate: '2008-08-06',
        jobToDate: '2012-04-04',
        jobTitle: 'Software Engineer',
        jobSummary: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a 
        galley of type and scrambled it to make a type specimen book.`
    },
   
],
    showSocial: true,
    inputs: [{
        name: 'hola'
    }],
    template: 'template1',
    referenceData: [{
        name: 'John',
        title: 'Marketing Head',
        email: 'johndoe@test.com',
        phone: '1232131233'
    },{
        name: 'Sam',
        title: 'Marketing Head',
        email: 'johndoe@test.com',
        phone: '1232131233'
    },{
        name: 'Charlie',
        title: 'Marketing Head',
        email: 'johndoe@test.com',
        phone: '1232131233'
    },{
        name: 'Joe',
        title: 'Marketing Head',
        email: 'johndoe@test.com',
        phone: '1232131233'
    }],

    selectedFont: 'helvetica'

};

export default data;