import React from 'react';
import Icon from './Icon';
import { faCube, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import {TEMPLATES, FONTS} from './constants';


const exportPdf = () => {
    window.print();
}

function Header({dispatch, initialValues}) {
    const {template, selectedFont} = initialValues;

    const handleSelectedFontChange = (event) => {
        const {target} = event;
        const {name, value} = target;
        dispatch({
            type: 'field',
            field: name,
            value: value
        })
    }

    const handleSelectedTemplateChange = (event) => {
        const {target} = event;
        const {name, value} = target;
        dispatch({
            type: 'field',
            field: name,
            value: value
        })
    }

    return (
        <div className='template-container'>

            <div className='App-logo'>
                {/* <span className='logo-title'>
                    <Icon icon={faCube} />  {' '}
                    BuildCV <br /></span> */}
                {/* <p className='logo-subtitle'>An initiative to help people build CV.</p> */}
                <a className="navbar-brand" href="#"><Icon icon={faCube} />  {' '}
                    BuildCV<small>An initiative to help people build CV.</small></a>
            </div>
            
            <div className="form-floating">
                <select name="template"
                        className="form-select"
                        value={template}
                        id="templateTheme"
                        aria-label="Select theme"
                        onChange={handleSelectedTemplateChange}>
                    {TEMPLATES.map(item => <option key={item} value={item}>{`Theme ${item && item.split('template')[1]}`}</option>)}
                </select>
                <label htmlFor="templateTheme">Selected theme</label>
            </div>
            <div className="form-floating">
                <select name="selectedFont" 
                        className="form-select" 
                        value={selectedFont} 
                        id="selectedFont" 
                        aria-label="Select font style"
                        onChange={handleSelectedFontChange}>
                    {FONTS.map(item => <option key={item} value={item}>{item}</option>)}
                </select>
                <label htmlFor="selectedFont">Selected font</label>
            </div>
            <div className='print-container'>
                <button 
                    type='button'
                    data-splitbee-event={`Print CV - Template ${template}`}
                    className='print-button fs-5 text'
                    onClick={exportPdf}>{' '}
                    <Icon icon={faFilePdf} />
                    {' '} Print CV
                </button>
                {/* <button 
                    type='button'
                    className='print-button fs-5 text'
                    onClick={exportPdf}>{' '}
                    <Icon icon={faFilePdf} />
                    {' '} Print CV
                </button> */}
            </div>
        </div>
    );
}

export default Header;