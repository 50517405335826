import React from 'react'
export default function SocialComponent(props) {
    const {initialValues} = props;
    const {
        linkedinURL,
        twitterURL,
        githubURL,
        websiteURL
    } = initialValues;
    const handleChange = (event) => {
        const {target} = event;
        const {name, value} = target;
        props.dispatch({
            type: 'field',
            field: name,
            value
        })
    }
    return (
      <div>
            {/* <div className="mt-3">
                    <h4>Social</h4>
            </div> */}
            <div className="mb-3">
                <label htmlFor="linkedinURL" className="form-label">Linkedin</label>
                <input type="text" value={linkedinURL} name="linkedinURL" onChange={handleChange} className="form-control" id="linkedinURL" aria-describedby="linkedinURL" />
            </div>
            <div className="mb-3">
                <label htmlFor="twitterURL" className="form-label">Twitter</label>
                <input type="text" value={twitterURL} name="twitterURL" onChange={handleChange} className="form-control" id="twitterURL" aria-describedby="twitterURL" />
            </div>
            <div className="mb-3">
                <label htmlFor="githubURL" className="form-label">Github</label>
                <input type="text" value={githubURL} name="githubURL" onChange={handleChange} className="form-control" id="githubURL" aria-describedby="githubURL" />
            </div>
            <div className="mb-3">
                <label htmlFor="websiteURL" className="form-label">Portfolio</label>
                <input type="text" value={websiteURL} name="websiteURL" onChange={handleChange} className="form-control" id="websiteURL" aria-describedby="websiteURL" />
            </div>
      </div>
    );
  }