import React from 'react';

function ProfessionalSkillsComponent(props) {
    const {technicalSkills, badgeColor = 'bg-dark'} = props;
    return (
        
        <div className="mb-3">
            <h6> PROFESSIONAL SKILLS </h6>
            <label htmlFor="emailLabel" className="form-label">
                {
                    (technicalSkills || []).map((item, idx) => {
                        return (
                            <span key={idx} className={`badge rounded-pill ${badgeColor}`}>{item.value}</span>
                        )
                    })
                }
            
            </label>
        </div>
    );
}

export default ProfessionalSkillsComponent;