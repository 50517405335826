import React from 'react';
import parse from 'html-react-parser';

function HTMLParserWrapperComponent(props) {
    const {content} = props;
    return (
        <>
            {parse(content)}
        </>
    );
}

export default HTMLParserWrapperComponent;