

import React from 'react';
import '../App.scss';
import 'bootstrap/dist/js/bootstrap.bundle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faGlobe, faEnvelope } from '@fortawesome/free-solid-svg-icons'
// import profilePic from '../images/150.png';
import SocialIconsComponent from '../widgets/SocialIconsComponent';
import LanguagesDataComponent from '../widgets/LanguagesDataComponent';
import SoftSkillsComponent from '../widgets/SoftSkillsComponent';
import ProfessionalSkillsComponent from '../widgets/ProfessionalSkillsComponent';
import ContactComponent from '../widgets/ContactComponent';
import EducationDataComponent from '../widgets/EducationDataComponent';
import ReferencesDataComponent from '../widgets/ReferencesDataComponent';
import ExperienceDataComponent from '../widgets/ExperienceDataComponent';
import HTMLParserWrapperComponent from '../widgets/HTMLParserWrapperComponent';

function Template9(props) {
    const {name, about, email, address, phone, title, technicalSkills, languages, softSkills,
        linkedinURL, twitterURL, websiteURL,
        educationData,
        jobData,
        showSocial,
        referenceData,
        profilePic
    } = props;

    return (
        <>
            <div className="item-2 p-3 mb-5 bg-white">
                

            <div className='left'>
                <div className='mb-4'>
                    <h4 className="display-6">{name && name.toUpperCase()}</h4>
                </div>
                <div className='mb-4'>
                        <h6 className="display-7 underlined">{`ABOUT ME`}</h6>
                        <small>
                            <HTMLParserWrapperComponent content={about} />
                        </small>
                    </div>
                    <div className="mb-3">
                        <h6 className="display-7 underlined"> EXPERIENCE </h6>
                        <ExperienceDataComponent jobData={jobData} />
                    </div>
                    <div className="mb-3">
                        <h6 className="display-7 underlined"> EDUCATION </h6>
                        <EducationDataComponent educationData={educationData} />
                    </div>

                    <div className="mb-3">
                        <h6 className="display-7 underlined"> REFERENCES </h6>
                        <ReferencesDataComponent referenceData={referenceData} />
                    </div>
                </div>
                
                
                
            </div>
            <div className="item-3 p-3 mb-5 bg-white">
                
                <div className='right'>
                
                    <div className='mb-4'>
                        <h6>{title}</h6>
                    </div>
                    <ContactComponent 
                        email={email} 
                        phone={phone} 
                        address={address}
                        color="#6c757d"  
                    />
                    <ProfessionalSkillsComponent technicalSkills={technicalSkills} />
                    <SoftSkillsComponent softSkills={softSkills} />
                    <LanguagesDataComponent languages={languages} />
                    <SocialIconsComponent 
                      linkedinURL={linkedinURL} 
                      websiteURL={websiteURL} 
                      twitterURL={twitterURL}
                      showSocial={showSocial}
                      color="gray"  
                  />

                    
                </div> 
                
                
            </div>
        </>
    );
}
export default Template9;