import React from 'react'
import EditorComponent from './widgets/EditorComponent';

export default function PersonalDetailsComponent(props) {
    const {initialValues} = props;

    const {
        name,
        email,
        title,
        address,
        phone,
        about
    } = initialValues;
    const handleChange = (event) => {
        const {target} = event;
        const {name, value} = target;
        props.dispatch({
            type: 'field',
            field: name,
            value
        })
    }

    const handleEditorChange = ({event}) => {
        const {target} = event;
        const {name, value} = target;
       
        props.dispatch({
            type: 'field',
            field: name,
            value
        })
    }

    return (
      <div>
                <div className="mb-3">
                    <label htmlFor="title" className="form-label">Job Title / Current Position</label>
                    <input name="title" value={title} type="text" onChange={handleChange} className="form-control" id="title" aria-describedby="job title" />
                </div>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input name="name" value={name} type="text" onChange={handleChange} className="form-control" id="name" aria-describedby="person name" />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email address</label>
                    <input name="email" value={email} type="email" onChange={handleChange} className="form-control" id="email" aria-describedby="person email" />
                </div>
                <div className="mb-3">
                    <label htmlFor="address" className="form-label">Address</label>
                    <input name="address" value={address} type="text" onChange={handleChange} className="form-control" id="address" aria-describedby="person address" />
                </div>
                <div className="mb-3">
                    <label htmlFor="phone" className="form-label">Phone number</label>
                    <input name="phone" value={phone} type="text" onChange={handleChange} className="form-control" id="phone" aria-describedby="person phone" />
                </div>
                
                <div className="mb-3">
                    <label htmlFor="about" className="form-label">About me</label>
                    <EditorComponent name="about" content={about} handleChange={handleEditorChange} />
                </div>

      </div>
    );
  }