import React from 'react';
import HTMLParserWrapperComponent from '../widgets/HTMLParserWrapperComponent';

function ExperienceDataComponent(props) {
    const {jobData} = props;
    return (
        
        <div className="accordion" id="jobAccordion">                     
            {
                jobData.map((item, idx) => {
                    return (
                        <p className="title" key={idx}>
                            <small><strong>{item.jobCompanyName} - {item.jobLocation} | {item.jobFromDate} - {item.jobToDate} | {item.jobTitle}</strong></small> <br />
                            <small><HTMLParserWrapperComponent content={item.jobSummary} /></small>
                        </p>
                    )
                })
            }
        </div>
    );
}

export default ExperienceDataComponent;