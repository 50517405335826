import React, { useState } from 'react'

const ReferenceDetails = (props) => {
    const {index, referenceData} = props;

    const [references, setReferences] = useState(referenceData);

    const handleInputChange = (e) => {
        const {target} = e;
        const {dataset, parentNode, value} = target;
        const {name} = dataset;

        const index = Number(
            dataset.index ? dataset.index : parentNode.dataset.index
        );
        references[index][name] = value;
        setReferences(references);
       
        props.dispatch({
            type: 'field',
            field: 'referenceData',
            value: references
        })
    }

    return(
        <div data-index={index}>
            <div className="mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <input data-index={index} type="text" name={"name"} value={referenceData[index] && referenceData[index]['name']} data-name="name" onChange={handleInputChange} className="form-control" id="name" aria-describedby="reference name" />
            </div>
            <div className="mb-3">
                <label htmlFor="title" className="form-label">Title</label>
                <input data-index={index} type="text"  data-name="title" value={referenceData[index] && referenceData[index]['title']} onChange={handleInputChange} className="form-control" id="title" aria-describedby="reference title" />
            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input data-index={index} type="text"  data-name="email" value={referenceData[index] && referenceData[index]['email']} onChange={handleInputChange} className="form-control" id="email" aria-describedby="reference email" />
            </div>
            <div className="mb-3">
                <label htmlFor="phone" className="form-label">Phone</label>
                <input data-index={index} type="text" data-name="phone" value={referenceData[index] && referenceData[index]['phone']} onChange={handleInputChange} className="form-control" id="phone" aria-describedby="reference phone" />
            </div>
        </div>
    )
}

export default function ReferencesComponent({dispatch, initialValues}) {
    const {referenceData} = initialValues;
    const defaultValues = {
        name: '',
        title: '',
        email: '',
        phone: ''
    };
    
    const [items, setItems] = useState(referenceData);

    const onAddItem = (e) => {
        const updatedValue = (referenceData || []).concat(defaultValues);
        setItems(updatedValue);
        dispatch({
            type: 'field',
            field: 'referenceData',
            value: updatedValue
        })
    }

    const onRemoveItem = (e) => {
        const {target} = e;
        const {dataset, parentNode} = target;
        const index = Number(
            dataset.index ? dataset.index : parentNode.dataset.index
        );
        const updatedSelection = (items || []).filter(
            (row, idx) => idx !== index
        );
        setItems(updatedSelection);
        dispatch({
            type: 'field',
            field: 'referenceData',
            value: updatedSelection
        })
    }

    return (
      <div>
        <a className='btn btn-primary mb-3' onClick={onAddItem}>Add a Reference</a>
        {
            (items || []).map((item, idx) => {
                const index = 'reference-collapse-'+idx;
                const heading = 'reference-heading-'+idx;
                const name = referenceData[idx] && referenceData[idx].title;
                return(
                    <div key={idx}>
                        <div className="accordion-item" data-index={idx}>
                        <h2 className="accordion-header" id={heading} data-index={idx}>
                        <button className="accordion-button" data-index={idx} type="button" data-bs-toggle="collapse" data-bs-target={`#${index}`} aria-expanded="true" aria-controls={index}>
                            <a onClick={onRemoveItem} className='btn' data-index={idx}>X</a> {name}
                        </button>
                        </h2>
                        <div id={index} className="accordion-collapse collapse show" aria-labelledby={heading}>
                        <div className="accordion-body">
                                <ReferenceDetails index={idx} dispatch={dispatch} referenceData={referenceData} />
                        </div>
                        </div>
                    </div>
                    </div>
                )
            })
        }
        
      </div>
    );
  }