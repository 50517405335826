import React from 'react';
import Icon from './Icon';
import {faHeart} from '@fortawesome/free-solid-svg-icons'

function Footer(props) {
    return (
        <div className='footer'>
            <span className='title'>Made with <Icon icon={faHeart} color="red" /> by {' '}
                <a href="https://makeadifference.app" data-splitbee-event={`makeadifference`} className='bio' target={'_blank'}>makeadifference.app</a>
                {' '}
            </span> 
    
        </div>
    );
}

export default Footer;