import React from 'react';
import CreatableSelect from 'react-select/creatable';
export default function SkillsComponent(props) {
    const {initialValues} = props;
    const {
       technicalSkills,
       softSkills,
       languages
    } = initialValues;

    const handleChange = ({item, type}) => {
        props.dispatch({
            type: 'field',
            field: type,
            value: item
        })
    }
    return (
      <div>
            {/* <div className="mt-3">
                <h4>Skills</h4>
            </div> */}
            <div className="mb-3">
                <label htmlFor="technicalSkills" className="form-label">Technical Skills</label>
                <CreatableSelect
                    isMulti
                    name="technicalSkills"
                    onChange={(item) => handleChange({item, type: 'technicalSkills'})}
                    options={technicalSkills}
                    value={technicalSkills}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="softSkills" className="form-label">Soft Skills</label>
                <CreatableSelect
                    isMulti
                    name="softSkills"
                    onChange={(item) => handleChange({item, type: 'softSkills'})}
                    options={softSkills}
                    value={softSkills}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                />
            </div>
            <div className="mb-5">
                <label htmlFor="languages" className="form-label">Languages known</label>
                <CreatableSelect
                    isMulti
                    name="languages"
                    onChange={(item) => handleChange({item, type: 'languages'})}
                    options={languages}
                    value={languages}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                />
            </div>
      </div>
    );
  }