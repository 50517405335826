import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import {
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

function SocialIcons(props) {
    const {color, linkedinURL, twitterURL, websiteURL, showSocial} = props;
    return (
        
        <div className='mb-4'>
            {
                showSocial ? <>
                    <h6 className="display-9"> SOCIAL </h6>
                    <p className="social">
                        <small><FontAwesomeIcon icon={faLinkedin} color={color} />{' '}{linkedinURL}</small> {' '} <br />
                        <small><FontAwesomeIcon icon={faTwitter} color={color} />{' '}{twitterURL}</small> {' '} <br />
                        <small><FontAwesomeIcon icon={faGlobe} color={color} />{' '}{websiteURL}</small>
                    </p>
                </> : null
            }
            
        </div>
    );
}

export default SocialIcons;